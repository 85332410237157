export const THUMBOR_URL = `https://img.hipages.com.au/`;
export const THUMBOR_TYPE_IMAGE = 'smart';
// todo, clean these up
export const THUMBOR_TYPE_PDF = 'smart';

export const ATTACHMENT_TYPE_IMAGE = 2;
export const ATTACHMENT_TYPE_PDF = 3;

export const THUBOR_SIZE_IMAGE = '90x90';
export const THUBOR_SIZE_PDF = '68x90';

export const PROTOCOL_PATTERN = /^https?:\/\//;

export const imagesSizes = [1200, 768, 480, 360];

export function getThumborURL(imageUrl: any, sizeOfImage?: string, type?: string, filter?: string) {
  if (typeof imageUrl === 'string') {
    imageUrl = imageUrl.trim();
  }

  if (imageUrl === '' || !imageUrl.match(PROTOCOL_PATTERN) || imageUrl.includes(THUMBOR_URL)) {
    return imageUrl;
  }

  sizeOfImage = typeof sizeOfImage !== 'undefined' ? sizeOfImage : '0x0';
  type = typeof type !== 'undefined' ? type : 'smart';
  filter = typeof filter !== 'undefined' ? filter : '';

  let filters = '';

  if (filter !== '') {
    filters = `/filters:${filter}`;
  }

  // fit-in/320x240/filters:fill(white,true)
  const resource = `${sizeOfImage}/${type}${filters}/${encodeURIComponent(imageUrl)}`;

  return `${THUMBOR_URL}unsafe/${resource}`;
}
